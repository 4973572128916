<script>
import TreeItemLesson from "./TreeItemLesson.vue";
import { http } from "../../http";

const treeData = {
  tag: "Carregando dados...",
  is_main: true,
  children: [],
};

export default {
  components: {
    TreeItem: TreeItemLesson,
  },
  props: {
    treeData: {
      type: Array,
      required: true,
    },
  },
};
</script>

<template>
  <div>
    <div class="card-tree">
      <div class="card">
        <div class="card-body">
          <ul>
            <TreeItem class="item" :model="treeData"></TreeItem>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.item {
  line-height: 1.5;
}
.card-tree {
  margin: 10px 140px;
}
.card {
  margin-top: 1%;
}
</style>