<template>
  <div class="container-fluid">
    <div style="margin-bottom: 15px">
      <router-link to="metodologia">
        <button class="btn btn-outline-secondary">
          <i class="bi bi-arrow-left-circle"></i> Voltar
        </button>
      </router-link>
    </div>
    <div>
      <table class="table table-sm table-hover">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Company</th>
            <th scope="col">Token</th>
            <th scope="col">Solução</th>
            <th class="text-center" scope="col">Stop subscriptions</th>
            <th class="text-center" scope="col">Ativo</th>
            <th scope="col">created</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="course in this.list_courses" :key="course.course_id">
            <td>{{ course.course_name }}</td>
            <td>{{ course.company_name }}</td>
            <td>{{ course.course_external_exercise_id }}</td>
            <td>{{ course.flow_execution === 'original' ? 'Alya' : course.flow_execution === 'chatbots' ? 'Lumy' : '' }}</td>
            <td class="text-center">
              <i v-if="course.stop_subscriptions" class="fas fa-check text-success"></i>
              <i v-else class="fas fa-times text-danger"></i>
            </td>
            <td class="text-center">
              <i v-if="course.is_active" class="fas fa-check text-success"></i>
              <i v-else class="fas fa-times text-danger"></i>
            </td>
            <td>{{ formatDate(course.created) }}</td>
            <td>
              <div class="dropdown">
                <button
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  Opções
                </button>
                <div class="dropdown-menu">
                  <a class="dropdown-item" :href="'/?#/course/' + course.course_id">Ver Detalhes</a>              
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination"> <!-- v-if para apenas quando o list_courses for carregado -->
        <button v-if="currentPage>1" @click="prevPage" :disabled="currentPage === 1"><i class="bi bi-arrow-left-circle" style="
          font-size: 1.3rem;" ></i></button>
        <span>&nbsp; {{ currentPage }} de {{ totalPages }} &nbsp;</span>
        <button v-if="currentPage < totalPages" @click="nextPage" :disabled="currentPage === totalPages"><i class="bi bi-arrow-right-circle" style="
          font-size: 1.3rem;"></i></button>
      </div>
    </div>
  </div>
</template>
    
<script>
import { http } from "../../http";

export default {
  name: "CourseList",
  data() {
    return {
      course_filter: null,
      list_courses: [],
      currentPage: 1,
      totalPages: 1,
    };
  },
  mounted() {
    this.list_all_courses(this.currentPage);
  },
  methods: {
    async list_all_courses(page) {
      try {
        const payload = {
          current_page: parseInt(page),
        };
        const res = await http.post("painel/atend_comercial/list_courses_tutor/", payload);
        this.list_courses = res.data.data;
        this.totalPages = res.data.total_pages;
      } catch (error) {
        console.error('Erro ao listar cursos:', error);
      }
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit'};
      return new Date(dateString).toLocaleDateString('pt-BR', options);
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.list_all_courses(this.currentPage);
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.list_all_courses(this.currentPage);
      }
    },
  },
};
</script>    

<style>
button {
  margin: none  ;
  border: none;
  background-color: transparent;
  color: black;
  font-weight: bold;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
</style>