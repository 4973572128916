<template>
<!-- Page comands-->
<div>
    <!-- Componente Login-->
    <div v-if="login == false">
        <Login></Login>
    </div>
    <!-- Componente Painel-App -->
    <div v-if="login == true">
        <PainelVue :email_user="this.email_user" :name_user="this.name_user" :is_admin="this.is_admin" :is_staff="this.is_staff" :is_tutor="this.is_tutor"></PainelVue>
    </div>
</div>
</template>

<script>
import {
    useCookies
} from "vue3-cookies"
import {http} from "./http";
import Login from "./components/auth/Login.vue";
import PainelVue from "./components/shared/painel/Painel.vue";
export default {
    components: {
        Login,
        PainelVue,
    },
    head: {
       link: [
             {
                 rel: "icon",
                 href: require("./assets/img/icon-lys.png")
             },
          ]
       },

    data() {
        return {
            login: true,
            name_user: null,
            email_user: null,
            is_tutor: null,
            is_staff: null,
            is_admin: null,
        };
    },
    beforeMount() {
        var token = this.$cookies.get("user_session");
        if (token != null) {
            const payload = {
                init_user: token,
            };
            var response = http
                .post("info_user_for_token", payload)
                .then((response) => {
                    this.name_user =
                        response.data["first_name"] + " " + response.data["last_name"];
                    this.email_user = response.data["email"];
                    this.is_admin = response.data["is_admin"];
                    this.is_staff = response.data["is_staff"];
                    this.is_tutor = response.data["is_tutor"];
                    this.$cookies.set("set_user_info", response.data["is_admin"]);
                    // console.log("infor User", response.data, response);
                    
                })
                .catch(function (error) {});
        }
        
    },

    mounted() {
        var token = this.$cookies.get("user_session");

        var payload = {
            token: token,
        };

        if (token != null) {
            http
                .post("confirm_token", payload)
                .then((response) => {
                    // console.log("response criando app", response.data, response);

                    this.login = true;
                })
                .catch(function (error) {
                    this.login = false;
                    this.$cookies.remove("user_session");
                });
        } else {
            this.login = false;
        }
    },

}
</script>

<style>
.corpo {
    font-family: Helvetica;
    width: 96%;
    margin: 0 auto;
}

.pagina-enter,
.pagina-leave-active {
    opacity: 0;
}

.pagina-enter-active,
.pagina-leave-active {
    transition: opacity 1s;
}

.centralizado {
    text-align: center;
}

.bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

@media (min-width: 768px) {
    .bd-placeholder-img-lg {
        font-size: 3.5rem;
    }
}
</style>
