<script>
import Modal from "./Modal.vue";

export default {
  name: "TreeItem",
  components: { modal: Modal },
  props: {
    model: Object,
  },
  data() {
    return {
      isOpen: false,
      modalAction: "",
    };
  },
  computed: {
    isFolder() {
      return this.model.children && this.model.children.length;
    },
  },
  methods: {
    toggle() {
      if (this.isFolder) {
        this.isOpen = !this.isOpen;
      }
    },
  },
};
</script>

<template>
  <div>
    <li v-if="model.tag != ''">
      <div>
        <span :class="{ 'bold-items': isFolder }" v-html="model.tag"></span>
        &nbsp;
        <i
          v-if="isFolder"
          @click="toggle"
          :class="[
            'bi',
            isOpen ? 'bi-arrows-angle-contract' : 'bi-arrows-angle-expand',
          ]"
        ></i>
      </div>

      <ul v-show="isOpen" v-if="isFolder" class="list-items">
        <TreeItem
          class="item"
          v-for="model in model.children"
          :model="model"
          v-bind:key="model"
        >
        </TreeItem>
      </ul>
    </li>
  </div>
</template>

<style scoped>
.list-items {
  margin-left: 50px;
}
.bold-items {
  font-weight: bold;
}
</style>