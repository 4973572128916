<template>
<div class="container-fluid">

    <!-- Page Heading -->
    <h1 class="h3 mb-2 text-gray-800">Clientes</h1>
    <p class="mb-4">Listagem de clientes cadastrados no sistema.</p>
    <!-- Card for total clients -->
    <div class="col-xl-3 col-md-6 mb-4">
        <div class="card border-left-primary shadow h-100 py-2">
            <div class="card-body">
                <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                        <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                            Total de clientes</div>
                        <div class="h5 mb-0 font-weight-bold text-gray-800" align="right">
                            {{ this.clients.length}}</div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="card shadow mb-4">
        <div class="card-header py-3 d-flex justify-content-start align-items-center gap-3">
            <div>                
                <h5 class="m-0 font-weight-bold text-primary">Tabela de Clientes</h5>
            </div>

            <div class="creat client">
                <button type="button" class="btn btn-primary m-0 p-0 d-flex justify-content-center align-items-center" title="Criar novo cliente" data-toggle="modal" data-target="#creatClientModal" style="width: 25px !important; height: 25px; border-radius: 25px;"><i class="bi bi-plus font-weight-bold fs-4"></i></button>

                <!-- Modals -->
                <!-- ModalCreat -->
                <div class="modal fade" id="creatClientModal" tabindex="-1" role="dialog" aria-labelledby="creatClientModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="creatClientModalLabel">Novo cliente</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form>
                                    <div class="form-group d-flex flex-column align-items-start">
                                        <label for="recipient-name" class="col-form-label">Nome:</label>
                                        <input type="text" class="form-control" id="recipient-name" v-model="infoClient.client_name">
                                    </div>
                                    <div class="form-group d-flex flex-column align-items-start">
                                        <label for="message-text" class="col-form-label">Descrição:</label>
                                        <textarea class="form-control" id="message-text" v-model="infoClient.client_description"></textarea>
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                                <button type="button" class="btn btn-primary" @click="createNewClient">Criar</button>
                            </div>
                            <div v-if="this.catch_alert.type == 'success'">
                                
                                <div class="alert alert-success" role="alert">
                                    {{ this.catch_alert.message }}
                                </div>
                            </div>
                            <div v-if="this.catch_alert.type == 'error'">
                                <div class="alert alert-danger" role="alert">
                                    {{ this.catch_alert.message }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- ModalEdit -->
                <div class="modal fade" id="editClientModal" tabindex="-1" role="dialog" aria-labelledby="editClientModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="editClientModalLabel">Editar cliente</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form>
                                    <div class="form-group d-flex flex-column align-items-start">
                                        <label for="recipient-name" class="col-form-label">Nome:</label>
                                        <input type="text" class="form-control" id="recipient-name" v-model="infoClient.client_name">
                                    </div>
                                    <div class="form-group d-flex flex-column align-items-start">
                                        <label for="message-text" class="col-form-label">Descrição:</label>
                                        <textarea class="form-control" id="message-text" v-model="infoClient.client_description"></textarea>
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                                <button type="button" class="btn btn-primary" @click="updateClient">Salvar</button>
                            </div>
                            <div v-if="this.catch_alert.type == 'success'">
                                <div class="alert alert-success" role="alert">
                                    {{ this.catch_alert.message }}
                                </div>
                            </div>
                            <div v-if="this.catch_alert.type == 'error'">
                                <div class="alert alert-danger" role="alert">
                                    {{ this.catch_alert.message }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- ModalDelete -->
                <div class="modal fade" id="deleteClientModal" tabindex="-1" role="dialog" aria-labelledby="deleteClientModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="deleteClientModal">Deletar o cliente {{infoClient.client_name}}?</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                                <button type="button" class="btn btn-danger" @click="deleteClient">Deletar</button>
                            </div>
                            <div v-if="this.catch_alert.type == 'success'">
                                <div class="alert alert-success" role="alert">
                                    {{ this.catch_alert.message }}
                                </div>  
                            </div>
                            <div v-if="this.catch_alert.type == 'error'">
                                <div class="alert alert-danger" role="alert">
                                    {{ this.catch_alert.message }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- ModalCreateProject -->
                <div class="modal fade" id="createProjectModal" tabindex="-1" role="dialog" aria-labelledby="createProjectModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="createProjectModalLabel">Criar Projetos</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form>
                                    <div class="mb-3 d-flex flex-column align-items-start">
                                        <label for="recipient-name" class="col-form-label">Nome:</label>
                                        <input type="text" class="form-control" id="recipient-name" v-model="infoProject.project_name">
                                    </div>
                                    <div class="mb-3 d-flex flex-column align-items-start">
                                        <label for="message-text" class="col-form-label">Descrição:</label>
                                        <textarea class="form-control" id="message-text" v-model="infoProject.project_description"></textarea>
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                                <button type="button" class="btn btn-primary" @click="creatProjects">Salvar</button>
                            </div>
                            <div v-if="this.catch_alert.type == 'success'">
                                <div class="alert alert-success" role="alert">
                                    {{ this.catch_alert.message }}
                                </div>
                            </div>
                            <div v-if="this.catch_alert.type == 'error'">
                                <div class="alert alert-danger" role="alert">
                                    {{ this.catch_alert.message }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <!-- ModalEditProject -->
                <div class="modal fade" id="editProjectModal" tabindex="-1" role="dialog" aria-labelledby="editProjectModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="editProjectModalLabel">Editar Projetos</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form>
                                    <div class="mb-3">
                                        <label for="recipient-name" class="col-form-label">Nome:</label>
                                        <input type="text" class="form-control" id="recipient-name" v-model="infoProject.project_name">
                                    </div>
                                    <div class="mb-3">
                                        <label for="message-text" class="col-form-label">Descrição:</label>
                                        <textarea class="form-control" id="message-text" v-model="infoProject.project_description"></textarea>
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                                <button type="button" class="btn btn-primary" @click="editProject">Salvar</button>
                            </div>
                            <div v-if="this.catch_alert.type == 'success'">
                                <div class="alert alert-success" role="alert">
                                    {{ this.catch_alert.message }}
                                </div>
                            </div>
                            <div v-if="this.catch_alert.type == 'error'">
                                <div class="alert alert-danger" role="alert">
                                    {{ this.catch_alert.message }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- ModalViewProjects -->
                <div class="modal fade" id="modalProjects" tabindex="-1" role="dialog" aria-labelledby="modalProjectsLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-scrollable modal-xl">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="modalProjectsLabel">Grade de projetos</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            
                            <div class="modal-body d-flex flex-row">
                                <div class="container-fluid d-flex flex-row w-100 gap-5">
                                    <div v-for="project in this.projects" class="card border-left-primary shadow d-flex flex-column align-items-start" style="width: 30%; height: 300px;">                  
                                        <div class="card-header w-100 d-flex justify-content-start align-items-center">
                                            <h4 class="text-primary">{{project.name}}</h4>
                                        </div>

                                        <div class="card-body">                                            
                                            <div class="col d-flex flex-column align-items-start mx-0 px-0">
                                                <div class="text-sm font-weight-bold text-dark text-uppercase mb-1">
                                                    Descrição</div>
                                                <div class="h5 mb-4 font-weight-bold text-gray-800">
                                                    {{project.description}}</div>                                                
                                            </div>
                                        </div>

                                        <div class="card-footer w-100 mx-0 px-1">
                                            <div class="col-12">
                                                <div class="h5 mb-0 font-weight-bold text-gray-800" align="right">
                                                    <button align="right" type="button" class="btn btn-warning text-light d-flex align-items-center justify-content-center font-weight-bold" style="border-radius: 20px;" data-bs-toggle="modal" data-bs-target="#editProjectModal" v-on:click="infoProject.project_name = project.name, infoProject.project_description = project.description, infoProject.project_id = project.id">
                                                        Editar &nbsp;<i class="fa fa-edit"></i>
                                                    </button></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!--<div class="table-responsive">
                                    <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0" selectedClass="table-info">
                                        <thead>
                                            <tr>
                                                <th>Nome</th>
                                                <th>Descrição</th>
                                                <th>Editar Projetos</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="project in this.projects">
                                                <td>{{project.name}}</td>
                                                <td>{{project.description}}</td>
                                                <td>
                                                    <button align="right" type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#editProjectModal" v-on:click="infoProject.project_name = project.name, infoProject.project_description = project.description, infoProject.project_id = project.id">
                                                        <i class="fa fa-edit"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>-->
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!-- Tableclients -->
        <div class="card-body">
            <div class="table-responsive">
                <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0" selectedClass="table-info">
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Descrição</th>
                            <th class="text-center" style="width: 10%">Ações em clientes</th>
                            <th class="text-center" style="width: 18%">Projetos</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="client in this.clients" v-bind:key="client.id">
                            <td>
                                {{client.name}}
                            </td>
                            <td>{{client.description}}</td>
                            <td align="center">
                                <div class="d-flex justify-content-center gap-3">
                                    <button align="right" type="button" class="btn btn-primary" style="border-radius: 50px;" data-toggle="modal" data-target="#editClientModal" 
                                    v-on:click="infoClient.client_name = client.name, infoClient.client_id = client.id, 
                                    infoClient.client_description = client.description"><i class="bi bi-pencil-fill"></i></button>
                                    <button align="right" type="button" class="btn btn-danger" style="border-radius: 50px;"     data-toggle="modal" data-target="#deleteClientModal" v-on:click="infoClient.client_name = client.name, infoClient.client_id = client.id"><i class="fa fa-trash"></i></button>
                                </div>
                            </td>
                            <td align="center">
                                <div class="d-flex justify-content-center gap-3">
                                    <button align="right" type="button" class="btn btn-primary font-weight-bold" data-bs-toggle="modal" href="#modalProjects" @click="getProjects(client.id)">Exibir ({{client.count_project}})</button>
                                    <button align="right" type="button" class="btn btn-success font-weight-bold" data-bs-toggle="modal" href="#createProjectModal"  @click="infoProject.client_id = client.id">Criar &nbsp;&nbsp;<i class="bi bi-plus-circle"></i></button>
                                </div>
                            </td>              
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
</template>


  
<script>
import {    
    http
} from '../../http';
import MessageCards from "../shared/message-cards/MessageCards.vue";
import Loader from "../shared/painel/Loader.vue";
import ExtraMessageInformation from "../shared/extra-message-information/ExtraMessageInformation.vue";
export default {
    components: {
        "message-cards": MessageCards,
        "extra-message-information": ExtraMessageInformation,
        Loader,
    },
    name: 'Client',
    data() {
        return {
            clients: [],
            clients_per_page: [],
            current_page: 1,
            client_name: '',
            client_description: '',
            client_id: {},
            infoClient: {
                client_name: null,
                client_id: null,
                client_description: null
            },
            catch_alert: {
                type: null,
                message: null
            },
            projects: [],
            project_name: '',
            project_description: '',
            infoProject: {
                client_id: null,
                project_name: null,
                project_id: null,
                project_description: null
            },
        }
    },
    mounted() {
        this.getClients();
    },
    methods: {
        getClients: function () {
            var payload = {
                current_page: this.current_page,
            };
            http
                .post('read_client/', payload )
                .then(
                    (res) => this.clients = res.data
                )                
        },
        getNextPageOfClients: function () {
            if (this.client.id > 0) {
                var payload = {
                    current_page: this.current_page,
                };
                http
                    .post('read_client/', payload)
                    .then((res) => res.data)
                    .then(
                        (clients_per_page) =>
                        (this.clients_per_page = clients_per_page),
                    )
                    .then(() => {
                        for (var i = 0; i < this.clients_per_page.length; i++) {
                            this.clients.push(this.clients_per_page[i]);
                        }
                        this.clients_per_page = [];
                        this.current_page++;
                    });
            }
        },
        createNewClient: function () {
            var payload = {
                name: this.infoClient.client_name,
                description: this.infoClient.client_description,
            };
            http
                .post('create_client/', payload)
                .then((res) => res.data)
                .catch((error) => {
                    this.catch_alert.type = 'error';
                    this.catch_alert.message = 'Erro ao criar cliente!';
                    setTimeout(() => {
                        $('#creatClientModal').modal("hide");
                        this.catch_alert.type = null;
                    }, 4000);
                })
                .finally((client) => {
                    this.client = client;
                    this.catch_alert.type = 'success';
                    this.catch_alert.message = 'Cliente criado com sucesso!';
                    setTimeout(() => {
                        $('#creatClientModal').modal("hide");
                        this.catch_alert.type = null;
                        this.getClients()
                    }, 3000);
                });
        },
        updateClient: function () {
            var payload = {
                client_id: this.infoClient.client_id,
                name: this.infoClient.client_name,
                description: this.infoClient.client_description,
            };
            http
                .post('update_client/', payload)
                .then((res) => res.data)
                .catch((error) => {
                    this.catch_alert.type = 'error';
                    this.catch_alert.message = 'Erro ao criar cliente!';
                    setTimeout(() => {
                        $('#editClientModal').modal("hide");
                        this.catch_alert.type = null;
                    }, 4000);
                })
                .finally(() => {
                    this.catch_alert.type = 'success';
                    this.catch_alert.message = 'Cliente criado com sucesso!';
                    setTimeout(() => {
                        $('#editClientModal').modal("hide");
                        this.catch_alert.type = null;
                        this.getClients()
                    }, 3000);
                });
        },
        deleteClient: function () {
            var payload = {
                client_id: this.infoClient.client_id,
            };
            http
                .post('delete_client/', payload)
                .catch(function (error) {
                    this.catch_alert.type = 'error';
                    this.catch_alert.message = 'Erro ao deletar cliente!';
                    setTimeout(() => {
                        $('#deleteClientModal').modal("toggle");
                        this.catch_alert.type = null;
                    }, 4000);
                })
                .finally(() => {
                    this.catch_alert.type = 'success';
                    this.catch_alert.message = 'Cliente deletado com sucesso!';
                    setTimeout(() => {
                        $('#deleteClientModal').modal("toggle");
                        this.catch_alert.type = null;
                        this.getClients()
                    }, 3000);
                });
        },
        creatProjects: function(){
            // console.log(this.infoProject)
            var payload = {
                full_companies: false,
                client_id: this.infoProject.client_id,
                name:this.infoProject.project_name,
                description:this.infoProject.project_description,
            };
            http
                .post('create_project/', payload)
                .then((res) => res.data)
                .catch((error) => {
                    this.catch_alert.type = 'error';
                    this.catch_alert.message = 'Erro ao criar cliente!';
                    setTimeout(() => {
                        $('#creatProjectModal').modal("hide");
                        this.catch_alert.type = null;
                    }, 4000);
                })
                .finally(() => {
                    this.catch_alert.type = 'success';
                    this.catch_alert.message = 'Cliente criado com sucesso!';
                    setTimeout(() => {
                        $('#creatProjectModal').modal("hide");
                        this.catch_alert.type = null;
                    }, 3000);
                });
        },        
        getProjects: function (client_id) {
            var payload = {
                client_id: client_id,
                current_page: this.current_page
            };
            http
                .post('read_projects/', payload)
                .then((res) => res.data)
                .then((projects) => (
                    this.projects = projects
                ))
        },
        editProject: function () {
            // console.log("==============>",this.infoProject)
            var payload = {
                company_id: this.infoProject.project_id,
                name:this.infoProject.project_name,
                description:this.infoProject.project_description,
            };
            http
                .post('update_projects/', payload)
                .then((res) => res.data)
                .catch((error) => {
                    this.catch_alert.type = 'error';
                    this.catch_alert.message = 'Erro ao criar cliente!';
                    setTimeout(() => {
                        $('#editProjectModal').modal("hide");
                        this.catch_alert.type = null;
                    }, 4000);
                })
                .finally(() => {
                    this.catch_alert.type = 'success';
                    this.catch_alert.message = 'Cliente criado com sucesso!';
                    setTimeout(() => {
                        $('#editProjectModal').modal("hide");
                        this.catch_alert.type = null;
                        this.getClients()
                    }, 3000);
                });

        },
    }
}
</script>
  
  
<style>
  </style>
