<template>
  <div class="card border-left-info shadow h-100 py-2">
    <div class="card-body">
      <div class="row no-gutters align-items-center">
        <div class="col-12">
          <div class="text-xs font-weight-bold text-info text-uppercase mb-1">
            Mensagens de interacao
          </div>
          <div class="accordion" id="interactionMessagesAccordion">
            <div class="accordion-item" v-for="(field, index) in fields" :key="field.label">
              <h2 class="accordion-header" :id="'heading' + index">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  :data-bs-target="'#collapse' + index"
                  :aria-expanded="index === 0 ? 'true' : 'false'"
                  :aria-controls="'collapse' + index"
                >
                  {{ field.label }}
                </button>
              </h2>
              <div
                :id="'collapse' + index"
                class="accordion-collapse collapse"
                :class="{ show: index === 0 }"
                :aria-labelledby="'heading' + index"
                data-bs-parent="#interactionMessagesAccordion"
              >
                <div class="accordion-body">
                  <p><strong>Name:</strong> {{ field.value?.name || 'Não cadastrado' }}</p>
                  <p><strong>Type:</strong> {{ field.value?.type || 'Não cadastrado' }}</p>
                  <p><strong>Content Text:</strong> {{ field.value?.content_text || 'Não cadastrado' }}</p>
                  <img v-if="field.value?.content_url" :src="field.value.content_url" alt="Imagem" class="fixed-size-img">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InteractionMessages",
  props: {
    fields: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.accordion-button {
  font-weight: bold;
  text-transform: uppercase;
}

.fixed-size-img {
  width: 100%;
  max-width: 300px; 
  height: auto;
  display: block;
}
</style>